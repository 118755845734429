* {
  box-sizing: border-box;
  margin: 0;
  font-family: 'Lato', sans-serif;
}

.footer {
  height: 300px;
  background: #0c1b23;
}

a {
  font-weight: bold;
  color: #d1e6e6;
  transition: .2s;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

h1 {
  font-family: 'Playfair Display', serif;
  font-style: italic;
}