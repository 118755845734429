.events-page {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: #0c1b23;
  color: #d1e6e6;
  padding-top: 200px;
  padding-bottom: 75px;
  text-align: center;
}

.invited {
  width: 80%;
  margin: 0 auto 20px;
  font-family: 'Playfair Display', serif;
}

.events-invite {
  margin: 20px auto;
}

.event-box {
  margin: 20px auto 50px;
}

.stand-out {
  color: #0c1b23;
  background: #d1e6e6;
  font-weight: bold;
}

.event-event {
  text-decoration: underline;
  font-family: 'Playfair Display', serif;
  font-style: italic;
  margin-bottom: 12px;
}

.info-info {
  font-weight: 700;
  padding: 0 15px;
}

.event-circle {
  margin: auto;
  border: 1px solid;
  border-radius: 100%;
  height: 300px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 970px) {
  .events-page {
    padding-top: 200px;
  }
  .event-circle {
    height: 280px;
    width: 280px;
  }
}