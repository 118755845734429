::-webkit-scrollbar {
  width: 4px !important;
  height: 4px !important;
}

/* Track */

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  -webkit-border-radius: 10px !important;
  border-radius: 10px !important;
}

/* Handle */

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px !important;
  border-radius: 10px !important;
  background: #c2c7cb !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5) !important;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #c2c7cb !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}