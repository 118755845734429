.ceremony-title {
  margin: 10px auto 30px;
}

.ceremony-container {
  margin-bottom: 80px;
}

.ceremony-info {
  margin: 20px auto;
}

.windy-map {
  width: 80%;
}

.other-info {
  width: 100%;
  height: 100%;
  background: #0c1b23;
  color: #d1e6e6;
  padding-top: 200px;
  padding-bottom: 180px;
  text-align: center;
}

.circle-div {
  border-radius: 100%;
  width: 300px;
  height: 300px;
  margin: 50px auto;
  overflow: hidden;
}

.tent-pic {
  background: url('https://i.imgur.com/fCiPMnB.jpg');
  width: 100%;
  height: 100%;
  background-size: 133%;
}

.info-block {
  margin: 10px auto;
}

.resource-title {
  font-size: 2.1em;
  text-decoration: underline;
}

.kit-circle {
  padding: 75px 10px 0;
  border: 1px solid #d1e6e6;
  border-radius: 100%;
  width: 360px;
  height: 360px;
  margin: 30px auto;
}

.kit-link {
  font-size: 1.5em;
}

.shuttle-pic {
  background: url('https://www.summitexpress.com/denver-airport-shuttle/files/uploads/2019/05/dillon-ampitheater-summer.jpg');
}

.rental-cars {
  background: url('https://media3.s-nbcnews.com/j/newscms/2019_13/2798361/190325-rental-cars-cs-229p_9f30034b54ca5e3246b9cdfa3eb6dbb8.fit-760w.jpg');
}

.carpool {
  background: url('http://theparlourri.com/wp-content/uploads/a-clown-car-1.jpg');
}

.hitchhiker {
  background: url('https://www.snopes.com/tachyon/2018/05/vanishing_hitchhiker.jpg?resize=865,452');
  background-size: cover;
  background-position-x: -170px;
}

.tent-out {
  background: url('https://images.unsplash.com/photo-1504280390367-361c6d9f38f4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80');
  background-size: cover;
  background-position-x: -80px;
}

.other-tent {
  background: url('https://www.nps.gov/cato/planyourvisit/images/owcc-small.jpg');
}

.tent-pack-pic {
  background: url('../../assets/half-dome-tent.jpg');
}

.rv-pic {
  background: url('https://www.visitwisecounty.com/wp-content/uploads/2018/07/rv-camping-in-woods.jpg');
  background-position-x: -75px;
}

.camper-van {
  background: url('https://images.squarespace-cdn.com/content/v1/5575b615e4b09fc993261f99/1572231938275-RZ2XRM2DKWGB42XZ8IFJ/ke17ZwdGBToddI8pDm48kEpVg-ILAPna1wRh-xAJ9fRZw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZUJFbgE-7XRK3dMEBRBhUpwEv36x-EUL2-BSQ5feDhwGCbXuJBFqZ-erYzVouT8yOb9TwqchglLQOCYTRn7ZGxI/image-asset.jpeg?format=500w');
}

.soviet-hotel {
  background: url('https://www.hotelbusiness.com/wp-content/uploads/2018/07/crop-Motel6.jpg');
}

.campground-info {
  width: 90%;
  max-width: 800px;
  margin: 0 auto 60px;
}

.campground-hr {
  width: 40%;
  margin: 20px auto;
}

@media (max-width: 970px) {
  .other-info {
    padding-top: 200px;
  }
  .kit-circle {
    padding: 15px 10px 0;
    width: 300px;
    height: 300px;
  }
}