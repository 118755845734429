.story-page {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: #0c1b23;
  color: #d1e6e6;
  padding-top: 200px;
  padding-bottom: 200px;
  text-align: center;
}

.our-story {
  width: 80%;
  margin: 20px auto;
  font-family: 'Playfair Display', serif;
}

.image-itself {
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.pic-div {
  margin: 50px auto
}

.li-0 {
  height: auto;
  margin: 0 10px;
}

.awssld {
  width: 80%;
  margin: auto;
}

.awssld_next:hover, .awssld_prev:hover {
  background: blue !important;
}

.awssld__content {
  background-color: #0c1b23;
}

.stories {
  display: flex;
  width: 90%;
  margin: auto;
  justify-content: space-around;
}

.story {
  margin: 0 20px;
}

.story-time {
  margin: 20px auto;
}

.the-story {
  max-width: 650px;
  margin: auto;
  text-align: justify;
  font-size: 1.2em;
  line-height: 1.3em;
}

.elopement-wrapper {
  margin-top: 100px;
  position: relative;
}

.elopement-wrapper>.the-story {
  margin-bottom: 50px;
}

@media (min-width: 1400px) {
  .slider-box {
    width: 100vw;
  }
  .one-slide {
    width: 100vw;
  }
  .photo-box {
    width: 100vw;
  }
}

@media (min-width: 970px) {
  .slider-box {
    width: 100vw;
  }
  .one-slide {
    width: 100vw;
  }
  .photo-box {
    width: 100vw;
  }
  .slider-box {
    height: 600px;
    overflow: hidden;
    margin: auto;
    position: relative;
  }
  .slider {
    display: flex;
    height: 100%;
    position: relative;
    transition: .2s;
  }
  .slide-btn-box {
    position: absolute;
    top: 0;
    height: 100%;
    width: 120px;
    z-index: 10;
    cursor: pointer;
  }
  .slide-btn-box:hover {
    filter: brightness(1.5);
  }
  .slide-btn-box:hover .slide-btn {
    filter: brightness(1.3);
    transform: scale(1.2);
  }
  .right-side {
    background: radial-gradient(#404f568c, #ffffff00);
    right: -60px;
  }
  .left-side {
    background: radial-gradient(#404f568c, #ffffff00);
    left: -60px;
  }
  .slide-btn {
    position: absolute;
    top: 45%;
    width: 60px;
    height: 60px;
  }
  .next {
    right: 60px;
  }
  .prev {
    left: 60px;
  }
  .one-slide {
    height: 100%;
  }
  .photo-box {
    padding: 0 60px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 1200px) {
  .awssld {
    width: 90%;
  }
}

@media (max-width: 1000px) {
  .stories {
    flex-direction: column;
  }
  .story {
    margin-bottom: 60px;
  }
}

@media (max-width: 970px) {
  .slider-box {
    width: 300px;
    margin: auto;
  }
  .slider {
    display: flex;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scroll-snap-points-x: repeat(300px);
    scroll-snap-type: x mandatory;
  }
  .slider>div {
    /* make sure the width is honored */
    flex-shrink: 0;
    width: 300px;
    height: 300px;
    scroll-snap-align: start;
  }
  .photo-box {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .slide-btn {
    display: none;
  }
  .story-page {
    padding: 0;
    padding-top: 200px;
  }
  .slides-holder {
    overflow: scroll;
  }
  .awssld {
    width: 100%;
  }
  .slide-nav-btn {
    display: none;
  }
  .stories {
    width: 100%;
  }
}