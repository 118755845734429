.flower-boots {
  background: url('https://junebugweddings.com/wedding-blog/wp-content/uploads/2017/10/IMG_8161.jpg');
  background-position-y: -97px;
}

.camp-casual {
  background: url('https://images-na.ssl-images-amazon.com/images/I/81oxDPSstjL._AC_UY879_.jpg');
}

@media (max-width: 970px) {
  .flower-boots {
    background-position-y: -60px;
  }
}