.frank {
  background: url('https://lh3.googleusercontent.com/DTDip_M9mOKEH5gVkxyZYz5pVFcdysfxPqVg9FK3YMeDOd48MpFkNIFCzPD1rSPICn9tU5oK5dZh_lMXooW7K0Bof8ikuYsqqlr2NZJUO4Z-J9UtzZduZktnPBO35Bgm8J4PlQVJePwVMKluMAEbn8W4OnaUlI_zIqT580d4auVGdBwfkWGZirveZGHqb24A4bZHzbEp3BBLdzFcNwYqJLnWp-wUo-v2MW7grcj7_yLlxq9BseNen7xsOCSE6b0Wtgyc66OKhY_8dY5OA7I9sgP6n3b9DhK76E_nN4kgK2oZxdprYxRdMyJeBCu3KVY2I_Z0Gjmi3B5AgC6W1rWTzFiOTDljOe_bPf9mbXW9Ozl_7IjhCJVPSxnlVVZUlW6bWssVJYE0f1UQ2LKFcQNkXMIf41rbxpwrJza_dJy_YOaJ2_YDwC7HY9DNzHrwI-HVGkfQhSE1CpLsH3WSqtnvMUn0j4ndqszK0vkM8Efna7UrKObet7JslPZmM6cD_f4mEs2xboyHKRa8Okqzau-RhuKa5_ZbFvVCbwObbGGO1llfU-rjS4qX1knFiiCKQQ-QE1if4JSDp2Y4MXON0Zr-QqANwmJNhS8mtQhegGJTu7Ik9lcryOjB0bDx8AXy-9bUayqAnedNsIMNYy3q-Icg5ixJTCVqFkOp58W_F_cIdBwdDemU57qDzcntpNwHUIRSp-1e31MJkAY_2SsSCYiIV9QUaXe4r2vVefGA1KKxt3wLbNQq2w=w990-h1756-no');
  background-size: cover;
  background-position-y: -6px;
}

.miah {
  background: url('https://lh3.googleusercontent.com/6jPrfOEpf6cg2nR4-2BqKxWOZcmGsdyW2YGh91tI935dVM2UtcaqCbPamMlmrGpU7yMrNmI-MOFSAsJnyTtr5Bs7th9kYbYnfdKXKdNKDKzRETAwiK6zBiuopltR9uCrsZLU8HaWdj5PHB9BXKoV0uvOAGzDZutTBiQHOTlM3iLuvPzSxKrPtwXkwYGaup-1xIZh_oVxmV-LmgZA3Hh0TylyH7BPWCF3pSZhXFRi9e5fB8IS_05R1e_XDqQgMTY4QUcVYmgmm3faUmb-BMOsKTYxmPNMSw3MpRT3cXCv3ue-UE0MDf4oNhCR0-O0wk3LWW0cShgtvOt1phO-Oi35L-0Xlu7DDDmjZLEK-01OAZp0iDIJNYmDD2rBF983TPo1m0B3zQtcxzJLcPyGGqxzSVSnXsjINlIXek_12RXK29ffx5g_fnw0ummt6Ci0G2RZ5qG6hLgadW8XWDgoWBR0qhZGR6ngVc3zuexpwbtY8EKvlS0hk5Z9Ok4j92v5MwHNduBBAihgpLEW8Xc9t0CCVwaoElSkUi6OExRT3fLGW4IAGjMccl7QAB4J5_hAcGqbgWFrGVHzWcS91j1McgY52oJq2UsFZuZIxdaTx6SlNYyhC5cz6bX-rY6QJrlf3KkMVz6D14NOKEGG9akQUhtQj0oEFBzx89OZgP8aNZ7faW08hWC-MHiSVYd_eNiBofUM1phZdwKj8mvnMRwT8YkZ7FZx4U9KA474EaWyBPtjSmbHSWsITg=w804-h674-no');
  background-position-x: -14px;
}

.delilah {
  background: url('https://lh3.googleusercontent.com/ANOeYCgIC0LobeNIOnJxCkILwlBKJXgoPvmRf8srHijR1X0M3AKCmRKQz4LZVicHSuXN2-AqFJZE4SEJRbNTIgyJH6KbsRJ1NauAJd7ZLLtyLDE0BkQjEt0oIpI_3QHxxzRrcqMGO04ka7EaKtGJaC2B8GGfogA5AHug7-xv2Gwk1UmR0B_ubxJhAZabSe81Yxg6o10W-wgutvMhSxxJ65lVj_eHIbJpUfOT8WxvWgYUv5EZmrlckZtLa2OBBHrJ42x2jPtQH2Eo5f1XW_tZdIZ5ISEBhjmsKumUlUJYaHwb_Zv9qI2Z7A7OVt0NpyALIC8l7g9nH050eQnTKDOh4W0CxO2za8bdgnEyf7qx-Idu5_ccob1-t59jPZkaaceZNua9pGWUGRwg7YARUcGm7fdGdqghD3FFYjNhFoziZ25fGxydADK99XKZEIoG0Gg9XSRL_dcJIi0Vf-IpPTORhqBqjW8BmBZX58K04-uy1wKK4k_uBkq1ZPlAo4wpnVymb4N1saKu1YrI5Zt1M5jfx7g9TMUv6JjFH35vv1WGm3zGIlrnRjqaetu0qSXj1vfM4xkpua0tFwohPEjNltPerBvirSYiBE4d4OMsiSgsZrnw8OuRFgXDxfsRZwBsR43MIu8OzWdLChMjIiV1GPVkS5IMtBlQWBXI6yZR1NDW4EfBfnkZIGFXcEZMBPAhJEp-6vbH6coG6I0-r57BLTu-JlgL_Oym4vxEZqOd0y1JSKDJN1BvkA=w1318-h1756-no');
}