.home-page {
  width: 100%;
  /* height: 1000px; */
  background: #0c1b23;
  color: #d1e6e6;
  padding-top: 200px;
  text-align: center;
  min-height: 100vh;
  padding-bottom: 100px;
}

.date {
  font-size: 2em;
  margin: 40px auto -30px;
  width: 230px;
  border: solid 2px;
  border-radius: 100%;
  height: 230px;
  padding: 50px 0 0;
  transition: .8s ease-out;
  position: relative;
}

.date-off {
  bottom: 500px;
}

.date-move {
  bottom: 0;
}

.names {
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 3.4em;
  margin: auto;
}

.name-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Playfair Display', serif;
  transition: .8s ease-out;
  position: relative;
}

.chels {
  text-align: right;
}

.aaron {
  text-align: left;
}

.ampersand {
  font-size: 4.5em;
  font-style: italic;
  margin: 0 25px;
  font-family: 'Playfair Display', serif;
  font-style: italic;
}

.aaron-off {
  left: 800px;
}

.aaron-move {
  left: 0;
}

.chels-off {
  right: 800px;
}

.chels-move {
  right: 0;
}

.location-info {
  position: relative;
  transition: .8s ease-out;
}

.coords-off {
  top: 500px;
}

.coords-move {
  top: 0;
}

.coordinates {
  font-size: 1.8em;
  margin: 30px auto 20px;
}

.windy {
  font-size: 1em;
  margin-bottom: 30px;
}

.strike-it {
  text-decoration: line-through;
}

@media (max-width: 1050px) {
  .date {
    margin-bottom: 10px;
  }
  .names {
    flex-direction: column;
  }
  .ampersand {
    font-size: 1em;
    width: 100%;
    margin: auto;
  }
  .chels, .aaron {
    text-align: center;
  }
}

@media (max-width: 500px) {
  .chels, .aaron {
    font-size: .7em;
  }
}

@media (max-width: 970px) {
  .home-page {
    padding-top: 200px;
  }
}