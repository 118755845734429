.whole-header {
  position: fixed;
  z-index: 20;
}

.header-image {
  top: 0;
  background-image: url('https://i.imgur.com/VgZ4dMs.jpg');
  width: 100vw;
  height: 100px;
  background-size: cover;
  background-position-y: 29%;
  background-color: #0c1b23;
}

.nav-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, .1);
  z-index: 3;
  top: 100px;
}

.header {
  background-color: #0c1b23;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, .1);
  width: 100%;
  display: flex;
  z-index: 3;
  transition: .5s ease;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.logo-link {
  cursor: pointer;
}

.logo-link:hover .logo-icon {
  background: #cecece;
}

.logo-icon {
  display: block;
  float: left;
  font-size: 16px;
  padding: 4px;
  text-decoration: none;
  width: 40px;
  height: 40px;
  background-size: contain;
  margin-left: 20px;
  border-radius: 100%;
  background: #d1e6e6;
}

.header a, .header span {
  color: #d1e6e6;
  display: inline;
  align-items: center;
  font-weight: bold;
}

.home-span {
  margin-left: 10px;
  line-height: 36px;
}

.header ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.header li a, .header li span {
  padding: 10px 15px;
  border-right: 1px solid #6f6d6d;
  text-decoration: none;
  height: 40px;
}

.header li a:hover, .header .menu-btn:hover, .header li span:hover {
  background-color: #484747;
}

.header li {
  line-height: 36px;
  position: relative;
}

.dropper-link:hover {
  background-color: #0c1b23 !important;
}

.header .menu {
  clear: none;
  float: right;
  max-height: none;
  margin-right: 15px;
  display: flex;
  align-items: center;
}

.header .menu-btn {
  display: none;
}

.chev {
  width: 25px;
  height: 25px;
  position: relative;
  top: 7px;
  transition: .2s;
}

.dropper-link:hover>.chev {
  transform: rotate(180deg);
}

.dropper:hover .chev {
  transform: rotate(180deg);
}

.chev-down {
  transform: rotate(180deg);
}

@media (max-width: 1040px) {
  .chev {
    top: 0px;
  }
  .header .menu-icon {
    display: none;
  }
  .logo-link {
    line-height: 36px;
  }
  .header .menu-icon .navicon {
    background: #d1e6e6;
    display: block;
    height: 2px;
    position: relative;
    transition: background .2s ease-in-out-out;
    width: 18px;
  }
  .header .menu-icon .navicon:before, .header .menu-icon .navicon:after {
    background: #d1e6e6;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
  }
  .header .menu-icon .navicon:before {
    top: 5px;
  }
  .header .menu-icon .navicon:after {
    top: -5px;
  }
  .header .menu-btn:checked~.menu {
    max-height: 100%;
    height: 100vh;
    overflow: hidden;
  }
  .header .menu-btn:checked~.menu-icon .navicon {
    background: transparent;
  }
  .header .menu-btn:checked~.menu-icon .navicon:before {
    transform: rotate(-45deg);
  }
  .header .menu-btn:checked~.menu-icon .navicon:after {
    transform: rotate(45deg);
  }
  .header .menu-btn:checked~.menu-icon:not(.steps) .navicon:before, .header .menu-btn:checked~.menu-icon:not(.steps) .navicon:after {
    top: 0;
  }
  .header .menu {
    margin-right: 0;
  }
  .home-span {
    display: none;
  }
  .whole-header {
    height: 300px;
    z-index: 10;
    position: static;
  }
  .header-image {
    width: 100%;
    height: 300px;
  }
  .fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
  .nav-box {
    width: 100%;
    postion: fixed;
    z-index: 10;
  }
  .header {
    display: block;
  }
  .hidden-header {
    display: none;
  }
  /* menu */
  .header .menu {
    clear: both;
    max-height: 0;
    transition: max-height .2s ease-in-out-out;
  }
  .menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
  .header ul {
    overflow: hidden;
  }
  .header li {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #0c1b23 !important;
  }
  .header li a, .header li span {
    border-bottom: 1px solid #6f6d6d;
    border-right: none;
    display: flex;
    justify-content: center;
    height: 40px;
  }
  .header .menu-icon {
    cursor: pointer;
    display: inline-block;
    float: right;
    padding: 20px 20px;
    position: relative;
    user-select: none;
  }
  .navver {
    position: relative;
    transition: .3s ease-out;
    margin-bottom: 20px;
  }
  .nav-out {
    right: 500px;
  }
  .nav-item-in-place {
    right: 0px;
  }
  .dropper {
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    transition: .2s;
  }
  .dropper:hover>.dropdown, .dropdown:hover {
    display: block;
  }
  .undrop-ul {
    height: 40px;
  }
  .drop-ul1 {
    height: 160px;
  }
  .drop-ul2 {
    height: 160px;
  }
  .drop-ul3 {
    height: 120px;
  }
  .dropdown {
    left: 0;
    visibility: visible;
    position: relative;
    transition: .2s;
    top: -80px;
  }
  .undropped {
    top: -80px;
  }
  .drop-it {
    top: 0;
  }
  .dropper-link {
    z-index: 9;
    cursor: pointer;
  }
  .dropped-link {
    width: 100%;
  }
  .dropped-link>a, .dropped-link>span {
    border-right: none !important;
  }
  .no-border {
    border: none !important;
  }
  .chev-up {
    transform: rotate(0) !important;
  }
}

@media (min-width: 1040px) {
  .dropper, .dropper-link {
    cursor: default;
  }
  .dropdown {
    visibility: hidden;
    opacity: 0;
    min-width: 5rem;
    position: absolute;
    transition: all 0.5s ease;
    margin-top: 1rem;
    left: 0;
    display: none;
  }
  .dropper:hover>.dropdown, .dropdown:hover {
    visibility: visible;
    opacity: 1;
    display: block;
    width: max-content;
    border-bottom: 1px solid #6f6d6d;
    background: #0c1b23;
    position: absolute;
    left: 0;
  }
  .dropped-link {
    clear: both;
    width: max-content;
  }
  .dropped-link>a, .dropped-link>span {
    border-right: none !important;
  }
}